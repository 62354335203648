import BikeIcon from '../icons/BikeIcon';
import CartCrossSell from './CartCrossSell';
import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import CloseIcon from '../icons/CloseIcon';
import FocusTrap from 'focus-trap-react';

import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const transitionClasses = {
  delay: 'delay-300',
  duration: 'duration-300',
};

const MiniCart = () => {
  const { cart, isMiniCartOpen, toggleMiniCart, miniCartData } =
    useCartContext();

  let percentage = 0;

  const thresholdInCents = miniCartData.settings.freeShippingThreshold * 100;
  const difference = thresholdInCents - cart.total_price;

  if (difference > 0) {
    percentage = (cart.total_price * 100) / thresholdInCents;
  } else {
    percentage = 100;
  }

  return createPortal(
    <FocusTrap active={isMiniCartOpen}>
      <div
        class={cn(
          'fixed inset-0',
          isMiniCartOpen
            ? 'opacity-1 visible'
            : `invisible opacity-0 ${transitionClasses.delay}`,
        )}
      >
        <div
          onClick={() => toggleMiniCart(false)}
          class={cn(
            `absolute inset-0 bg-primary-1 transition-opacity ${transitionClasses.duration}`,
            isMiniCartOpen ? 'opacity-50' : 'opacity-0',
          )}
        />
        <div
          class={cn(
            `absolute bottom-0 right-0 top-0 z-50 flex w-full flex-col bg-white transition-transform sm:max-w-2xl ${transitionClasses.duration}`,
            isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <div class="flex items-center bg-primary-1 px-4 py-4 text-white md:px-6">
            <BikeIcon className="mr-3 size-8 text-primary-2" />
            <p class="type-text-7 uppercase">
              {getTranslation('mini_cart.your_cart', {
                count: cart.item_count,
              })}
            </p>
            <button onClick={() => toggleMiniCart(false)} class="ml-auto">
              <CloseIcon className="size-8" />
            </button>
          </div>

          {miniCartData?.settings?.freeShippingThreshold > 0 && (
            <div class="relative flex h-12 items-center justify-center border-b border-primary-1 bg-primary-3 text-primary-1">
              <div class="type-text-8 relative z-10 uppercase">
                {percentage === 100
                  ? getTranslation('mini_cart.freeShippingCompleteMessage')
                  : getTranslation('mini_cart.freeShippingMessage', {
                      amount: formatMoney(difference, theme.moneyFormat),
                    })}
              </div>
              <div
                class="absolute inset-y-0 left-0 bg-primary-4"
                style={{ width: `${percentage.toFixed(2)}%` }}
              ></div>
            </div>
          )}

          <div
            class="flex h-full flex-1 flex-col overflow-auto"
            data-cart-items
            data-scroll-lock-scrollable
          >
            {cart.items && cart.items.length > 0 ? (
              cart.items.map(item => <CartItem item={item} key={item.key} />)
            ) : (
              <CartEmpty />
            )}
            <div class="mt-auto">
              <CartCrossSell />
            </div>
          </div>

          {cart.item_count > 0 && (
            <div class="bg-primary-1 p-4 text-white md:p-6">
              <CartTotals cart={{ ...cart }} />

              <div class="mt-4 flex items-center justify-between">
                <a href="/" class="button button--link">
                  {getTranslation('mini_cart.keep_shopping')}
                </a>
                <form action={`${Shopify.routes.root}cart`} method="POST">
                  <button
                    type="submit"
                    name="checkout"
                    class="button button--secondary"
                  >
                    {getTranslation('mini_cart.checkout')}
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </FocusTrap>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
